import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/base.scss'
import VueI18n from 'vue-i18n'
import i18n from './lang'
Vue.config.productionTip = false
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(ElementUI, {
  size: 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueI18n)
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
