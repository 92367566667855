import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 如果使用了 Element UI 在这里引入对应的语言包
import enLocaleElement from 'element-ui/lib/locale/lang/en'
import zhLocaleElement from 'element-ui/lib/locale/lang/zh-CN'
import ptLocaleElement from 'element-ui/lib/locale/lang/pt'
// 这里是自定义的语言包
import enLocale from './en'
import zhLocale from './zh'
import ptLocale from './pt'

// 引入
Vue.use(VueI18n)

// 引入 对应的语言包
const messages = {
    en: {
        ...enLocale,
        ...enLocaleElement
    },
    zh: {
        ...zhLocale,
        ...zhLocaleElement
    },
    pt: {
        ...ptLocale,
        ...ptLocaleElement
    }
}

// new 一个新的 I18n 并返回
export default new VueI18n({
    // set locale options: en or zh
    locale: 'en', // 设置显示 中文
    // set locale messages
    messages
})
