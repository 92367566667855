export default {
    login: 'Login',
    logout: 'Logout',
    logo: 'Plataforma de gerenciamento de patrimônio de criptomoeda',
    menu: {
        home: 'Página inicial',
        manageMoney: 'Gestão financeira',
        vip: 'Membro',
        account: 'Conta'
    },
    index: {
        more: "Mais",
        subTitle: "this is a test",
        unit: 'Rendimento',
        manageMoney: 'Gestão financeira',
        hq: 'Mercado',
        sylb: 'Lista de receitas',
        day: 'Dia',
        email: 'E-mail de atendimento ao cliente',
        name: 'Conta',
        value: 'Quantia de receita',
        date: 'Tempo de receita',
        products: 'Lista de produtos',
        buy: 'Comprar',
        product_num_min: 'Quantidade de compra (Mínima:',
        input_product_num: 'Insira a quantidade de compra',
        input_product_num_min: 'A quantidade de compra não deve ser menor que',
        input_login_name: 'Insira a conta de login',
        input_login_password: 'Insira a senha de login',
        input_login_name2: 'Insira a conta de login',
        input_login_password2: 'Insira a senha de login',
        register: 'Registrar',
        register2: 'Registrar',
        forget: 'Esqueceu a senha',
        forget2: 'Se você esquecer sua senha, entre em contato com o e-mail:',
        login_now: 'Já tem uma conta, faça login agora',
        register_login_name: 'Insira a conta de login',
        register_email: 'Insira seu e-mail',
       register_mobile: 'Número de celular',
        register_input_mobile: 'Insira seu número de celular',
        register_country_code: 'Selecione o código do seu país',
        register_login_password: 'Insira sua senha de login',
        register_login_password_again: 'Insira a conta de login novamente',
        register_trade_key: 'Insira sua chave de negociação',
        register_trade_key_again: 'Insira a chave de negociação novamente',
        register_input_login_name: 'Insira a conta de login',
        register_input_login_name_error: 'O comprimento da conta de login não deve ser inferior a 6 caracteres',
        register_input_email: 'Insira seu e-mail',
        register_input_right_email: 'Insira o e-mail correto',
        register_input_country_code: 'Selecione o código do seu país',
        register_input_login_password: 'Insira sua senha de login',
        register_input_login_password_error: 'O comprimento da senha de login não deve ser inferior a 6 caracteres',
        register_input_login_password_again: 'Insira a senha de login novamente',
        register_input_login_password_different: 'Duas senhas de login são inconsistentes',
        register_input_trade_key: 'Insira sua chave de negociação',
        register_input_trade_key_error: 'O comprimento da chave de negociação não deve ser inferior a 6 dígitos',
        register_input_trade_key_again: 'Insira a chave de negociação novamente',
        register_input_trade_key_different: 'Duas chaves de transação são inconsistentes',
        submit: 'Enviar registro',
        register_tip: 'Lembrete importante: Esta chave é necessária para os usuários sacar dinheiro, recarregar, transferir, alterar senhas, etc. Por favor, mantenha-a segura! ',
    },
    account: {
        account: 'Conta',
        recharge: 'Recarregar',
        withdrawal: 'Retirar',
        transfer: 'Transferir',
        vip_up_level: 'Atualização de membro',
        balance: 'Ativos totais da conta',
        balance2: 'Ativos totais da conta：',
        login: 'Login',
        services: 'Outros serviços',
        financing: 'Minha gestão financeira',
        security: 'Segurança da conta',
        message: 'Centro de mensagens',
        more_message: 'Mais mensagens',
        contact: 'Entre em contato com o atendimento ao cliente',
        question: 'Perguntas frequentes',
        wallet: 'Gestão de carteira',
        logout: 'Logout',
        contact_service: 'Entre em contato com o atendimento ao cliente：',
        hello: 'Olá',
        visitor: 'Visitante',
        account_security: 'Segurança de conta',
        email: 'E-mail',
        input_your_email: 'Insira seu e-mai',
        password: 'Senha de login',
        leave_blank_if_not_modified: 'Deixe em branco se não modificar',
        trader_password: 'Chave de transação',
        trader_tips: 'Dica',
        trader_confirm: 'Confirmar',
        trader_cancel: 'Cancelar',
        input_trader_password: 'Insira a chave da transação',
        input_your_trader_password: 'Insira sua chave da transação',
        confirm: 'Confirmar',
        transfer_confirm: 'Transferência sem senha',
         email_format_error: 'Erro no formato de email',
         email_length_error: 'O tamanho do email deve ser maior que 6 caracteres',
         wallet_add: 'Novo',
         wallet_edit: 'Editar',
         wallet_edit2: 'Editar',
         wallet_delete: 'Excluir',
         wallet_type: 'Tipo de carteira',
         select_wallet_type: 'Selecione o tipo de carteira',
         wallet_address: 'Endereço da carteira',
         wallet_address2: 'Endereço da carteira',
         input_wallet_address: 'Insira o endereço da carteira',
         select_wallet_address: 'Selecione o endereço da carteira',
        save: 'Salvar',
        input_right_wallet_address: 'Insira o endereço correto da carteira',
         select_wallet: 'Selecione uma carteira',
         wallet2: 'Endereço da carteira',
        wallet3: 'Carteira',
        amount: 'Valor de retirada',
        input_withdrawal_amount: 'Insira o valor de retirada',
        input_right_withdrawal_amount: 'Insira o valor correto de retirada',
        withdrawal_record: 'Registro de retirada',
        withdrawal_amount: 'Valor',
        withdrawal_status: 'Estado',
        withdrawal_date: 'Data',
        withdrawal_left: 'A partir da próxima retirada:',
        no_next_withdrawal_time: 'Ainda não é hora de retirar',
        recharge_account: 'Recarregar a conta',
        recharge_filling: 'Quero recarregar',
        recharge_image_tip: 'Envie a captura de tela da transferência com o número da transação',
        upload_voucher: 'Carregue o comprovante',
        select_upload_voucher: 'Carregue o comprovante de recarga',
        recharge_amount: 'Valor da recarga',
        input_recharge_amount: 'Insira o valor da recarga',
        input_right_recharge_amount: 'Insira o valor correto da recarga',
        recharge_record: 'Registro de recarga',
        recharge_amount2: 'Valor',
        recharge_status: 'Estado',
        recharge_date: 'Data',
        recharge_example: 'Exemplo de recarga',
        daily_money: 'Limite diário de transferência:',
        month_money: 'Limite mensal de transferência:',
        limit_days: 'Dias válidos:',
        upgrade_now: 'Atualize agora',
        time_remaining: 'Período de validade:',
        time_remaining2: 'Validade restante da associação:',
        time_remaining3: 'Validade da associação:',
        daily_money_left: 'Saldo restante da transferência de hoje:',
        daily_money_left2: 'Saldo restante da transferência de hoje:',
        month_money_left: 'Saldo restante da transferência deste mês:',
        month_money_left2: 'Saldo restante da transferência deste mês:',
        select_vip_level: 'Selecione um nível de associação',
        select_higher_vip_level: 'Compre um nível mais alto que o atual',
        transfer_to_user: 'Conta da outra parte',
        input_transfer_to_user: 'Insira a conta da outra parte',
        transfer_to_user_tip: 'Lembrete: a conta da outra parte deve ser um membro de primeiro nível para transferir',
        transfer_amount: 'Valor da transferência',
        input_transfer_amount: 'Insira o valor da transferência',
        transfer_amount_tip: 'Instruções de transferência: A conta da outra parte deve ser um membro de primeiro nível para transferir',
        copy_success: 'Copiado com sucesso',
        days: 'Dia ',
        day: 'Dia ',
        vip_remark: 'Exceto para VIP1, o USDT pago para outros níveis de associação VIP são todos depósitos. Quando sua assinatura expirar ou você não precisar mais usar a função de assinatura, envie um e-mail para o atendimento ao cliente para solicitar o reembolso do depósito. Nenhum USDT é cobrado pela ativação da associação.',

    },
    product: {
        agreement: 'Li e concordo com o Contrato de Produto Financeiro do USDT',
        agreement_tip: 'Marque para concordar com o contrato',
        financing_order: 'Ordem de gestão financeira',
        product_name: 'Nome do produto',
        product_num: 'Número de produtos',
        start_date: 'Data de início',
        end_date: 'Data de término',
        create_date: 'Data do pedido',
        order_amount: 'Valor do pedido',
        income_amount: 'Valor da receita',


    },
    common: {
        zh: '中文',
        en: 'English',
        pt: 'Português',
    }
}