export default {
    login: 'login',
    logout: 'logout',
    logo: 'Financial income platform',
    menu: {
        home: 'Home',
        manageMoney: 'Financing',
        vip: 'Vip',
        account: 'Account'
    },
    index: {
        more: "More",
        subTitle: "this is a test",
        unit: 'yield',
        manageMoney: 'Financing',
        hq: 'Quotation',
        sylb: 'Revenue',
        day: 'days',
        email: 'Revenue List Customer Service Email',
        name: 'username',
        value: 'amount',
        date: 'date',
        products: 'Products',
        buy: 'Buy',
        product_num_min: 'Quantity(at least:',
        input_product_num: 'Please input the quantity',
        input_product_num_min: 'The quantity should be more than ',
        input_login_name: 'login account',
        input_login_password: 'login password',
        input_login_name2: 'Please input the login account',
        input_login_password2: 'Please input the login password',
        register: 'register',
        register2: 'Register',
        forget: 'forgot password',
        forget2: 'Please contact the email if you forget your password: ',
        login_now: 'Login now',
        register_login_name: 'login account',
        register_email: 'e-mail address',
        register_mobile: 'mobile number',
        register_input_mobile: 'Please input the mobile number',
        register_country_code: 'country code',
        register_login_password: 'login password',
        register_login_password_again: 'confirm the login password',
        register_trade_key: 'trade key',
        register_trade_key_again: 'confirm the trade key',
        register_input_login_name: 'Please input the login account',
        register_input_login_name_error: 'The length of login account shall not be less than 6 characters',
        register_input_login_name_error2: 'The login account cannot contain spaces',
        register_input_email: 'Please input your e-mail address',
        register_input_right_email: 'Please input the correct e-mail address',
        register_input_country_code: 'Please input your country code',
        register_input_login_password: 'Please input the login password',
        register_input_login_password_error: 'The length of login password shall not be less than 6 characters',
        register_input_login_password_again: 'Please input the login password again',
        register_input_login_password_different: 'The two login passwords are different',
        register_input_trade_key: 'Please input the trade key',
        register_input_trade_key_error: 'The length of trade key shall not be less than 6 characters',
        register_input_trade_key_again: 'Please input the trade key again',
        register_input_trade_key_different: 'The two trade key are different',
        submit: 'Submit',
        register_tip: 'Important reminder: The user needs this key to withdraw cash, deposit, transfer money, change password, etc. Please keep it properly!',
    },
    account: {
        account: 'Account',
        recharge: 'Deposit',
        withdrawal: 'Withdraw',
        transfer: 'Transfer',
        vip_up_level: 'VIP Upgrade',
        balance: 'Balance',
        balance2: 'Balance:',
        login: 'Login',
        services: 'Other Srvices',
        financing: 'Financing',
        security: 'Security',
        message: 'Message',
        more_message: 'More',
        contact: 'Contact',
        question: 'Questions',
        wallet: 'Wallet',
        logout: 'Logout',
        contact_service: 'Please send email to : ',
        hello: 'Hello ',
        visitor: 'Visitor ',
        account_security: 'Account Security',
        email: 'Email',
        input_your_email: 'Please input your email',
        password: 'Password',
        leave_blank_if_not_modified: 'Please leave blank if not modified',
        trader_password: 'Trade key',
        trader_tips: 'Tips',
        trader_confirm_wallet_address: 'Confirm the wallet address',
        trader_input_wallet_address: 'Please input the wallet address again',
        trader_wallet_address_must_be_consistent: 'The two wallet addresses must be consistent',
        trader_confirm: 'confirm',
        trader_cancel: 'cancel',
        input_trader_password: 'Please input the trade key',
        input_your_trader_password: 'Please input your trade key',
        confirm: 'Confirm',
        transfer_confirm: 'One-click transfer without a key',
        email_format_error: 'Incorrect mailbox format',
        email_length_error: 'The mailbox length must be greater than 6 digits',
        wallet_add: 'Add wallet',
        wallet_edit: 'Edit wallet',
        wallet_edit2: 'edit',
        wallet_delete: 'delete',
        wallet_type: 'Wallet type',
        select_wallet_type: 'Please select wallet type',
        wallet_address: 'Wallet address',
        wallet_address2: 'Address',
        input_wallet_address: 'Please input the wallet address',
        select_wallet_address: 'Please select the wallet address',
        save: 'Save',
        input_right_wallet_address: 'Please input the correct wallet address',
        select_wallet: 'Please select your wallet',
        select_transfer_wallet: 'Please select wallet address',
        wallet2: 'Wallet',
        wallet3: ' Wallet',
        amount: 'Amount',
        input_withdrawal_amount: 'Please input the withdraw amount',
        input_right_withdrawal_amount: 'Please input the correct withdraw amount',
        withdrawal_record: 'Withdraw records',
        withdrawal_amount: 'Amount',
        withdrawal_status: 'Status',
        withdrawal_date: 'Date',
        withdrawal_left: 'Until next withdrawal:',
        no_next_withdrawal_time: 'It is not time for this withdrawal',
        recharge_account: 'Deposit account',
        recharge_filling: 'Deposit filling',
        recharge_image_tip: 'Please upload the transfer screenshot with transaction number.',
        upload_voucher: 'Voucher',
        select_upload_voucher: 'Please upload the deposit voucher',
        recharge_amount: 'Amount',
        input_recharge_amount: 'Please input the deposit amount',
        input_right_recharge_amount: 'Please input the correct deposit amount',
        recharge_record: 'Deposit records',
        recharge_amount2: 'Amount',
        recharge_status: 'Status',
        recharge_date: 'Date',
        recharge_example: 'Deposit example',
        daily_money: 'Daily transfer in limit:',
        month_money: 'Monthly transfer in limit:',
        limit_days: 'Valid days:',
        upgrade_now: 'Upgrade now',
        time_remaining: 'Duration: ',
        time_remaining2: 'VIP validity remaining: ',
        time_remaining3: 'VIP duration: ',
        daily_money_left: 'Today transfer in limit: ',
        daily_money_left2: 'Today transfer in limit',
        month_money_left: 'Month transfer in limit: ',
        month_money_left2: 'Month transfer in limit',
        select_vip_level: 'Please select the vip level.',
        select_higher_vip_level: 'Please purchase a higher level than the current one.',
        transfer_to_user: 'Account',
        input_transfer_to_user: 'Please input the transfer account',
        transfer_to_user_tip: 'Tip: the opposite account must be a first level vip to be transferred.',
        transfer_amount: 'Amount',
        transfer_checking: 'Wallet address checking...',
        transfer_fee: 'Service charges: ',
        transfer_received_amount: 'Received amount: ',
        transfer_time_remaining: 'Time remaining:  ',
        transfer_auto_clear: 'The system automatically clears after successful payment.',
        transfer_service_charge_tip1: 'If the service charge is paid successfully, ',
        transfer_service_charge_tip2: ' will transfer to your wallet address',
        transfer_service_charge_tip3: ' immediately.',
        transfer_service_charge_tip4: 'The service charge will be collected through the US blockchain digital currency exchange (not the platform), and the USDT will be received immediately after payment.',
        transfer_service_charge_tip5: 'Each member can only withdraw cash once within 15 days.',
        input_transfer_amount: 'Please input the transfer amount',
        input_right_transfer_amount: 'Please input the correct transfer amount',
        transfer_amount_tip: 'Tip: the opposite account must be a first level vip to be transferred.',
        copy_success: 'Copy successful',
        days: 'days ',
        day: 'day ',
        copy: 'copy',
        vip_remark: 'Except for VIP 1, the USDT paid by other VIP members is a deposit. When your member expires or no longer needs to use the membership function, you can send an email to the customer service to apply for returning the deposit. No USDT will be charged to the subscriber.',
    },
    product: {
        agreement: 'I have read and agreed to the USDT Financial Product Agreement',
        agreement_tip: 'Please check the agreement',
        financing_order: 'Financing order',
        product_name: 'product',
        product_num: 'quantity',
        start_date: 'start date',
        end_date: 'end date',
        create_date: 'create date',
        order_amount: 'order amount',
        income_amount: 'income amount',


    },
    common: {
        zh: '中文',
        en: 'English',
    }
}
