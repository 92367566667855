export default {
    login: '登录',
    logout: '退出',
    logo: '加密货币理财平台',
    menu: {
        home: '首页',
        manageMoney: '理财',
        vip: '会员',
        account: '账户'
    },
    index: {
        more: "更多",
        subTitle: "this is a test",
        unit: '收益率',
        manageMoney: '理财',
        hq: '行情',
        sylb: '收益列表',
        day: '天',
        email: '客服邮箱',
        name: '账户',
        value: '收益金额',
        date: '收益时间',
        products: '产品列表',
        buy: '购买',
        product_num_min: '购买数量(最小数量：',
        input_product_num: '请输入购买数量',
        input_product_num_min: '购买数量不得少于',
        input_login_name: '请输入登录账户',
        input_login_password: '请输入登录密码',
        input_login_name2: '请输入登录账户',
        input_login_password2: '请输入登录密码',
        register: '注册账号',
        register2: '注册账号',
        forget: '忘记密码',
        forget2: '忘记密码请联系邮箱：',
        login_now: '已有账号，立即登录',
        register_login_name: '请输入登录账号',
        register_email: '请输入您的邮箱',
        register_mobile: '手机号码',
        register_input_mobile: '请输入手机号码',
        register_country_code: '请选择您的国家代码',
        register_login_password: '请输入您的登录密码',
        register_login_password_again: '请再次输入登录账号',
        register_trade_key: '请输入您的交易密钥',
        register_trade_key_again: '请再次输入交易密钥',
        register_input_login_name: '请输入登录账号',
        register_input_login_name_error: '登录账号长度不得少于6位',
        register_input_login_name_error2: '登录账号不允许包含空格',
        register_input_email: '请输入您的邮箱',
        register_input_right_email: '请输入正确的邮箱',
        register_input_country_code: '请选择您的国家代码',
        register_input_login_password: '请输入您的登录密码',
        register_input_login_password_error: '登录密码长度不得少于6位',
        register_input_login_password_again: '请再次输入登录密码',
        register_input_login_password_different: '两次登录密码不一致',
        register_input_trade_key: '请输入您的交易密钥',
        register_input_trade_key_error: '交易密钥长度不得少于6位',
        register_input_trade_key_again: '请再次输入交易密钥',
        register_input_trade_key_different: '两次交易密钥不一致',
        submit: '提交注册',
        register_tip: '重点提醒：用户提现、充值、转账、修改密码等都需要该密钥，请妥善保管！',
    },
    account: {
        account: '账户',
        recharge: '充值',
        withdrawal: '提现',
        transfer: '转账',
        vip_up_level: '会员升级',
        balance: '账户总资产',
        balance2: '账户总资产：',
        login: '登录',
        services: '其他服务',
        financing: '我的理财',
        security: '账号安全',
        message: '消息中心',
        more_message: '更多消息',
        contact: '联系客服',
        question: '常见问题',
        wallet: '钱包管理',
        logout: '退出登录',
        contact_service: '请联系客服：',
        hello: '您好',
        visitor: '游客',
        account_security: '账户安全',
        email: '电子邮箱',
        input_your_email: '请输入您的电子邮箱',
        password: '登录密码',
        leave_blank_if_not_modified: '不修改请留空',
        trader_password: '交易密钥',
        trader_tips: '提示',
        trader_confirm_wallet_address: '确认钱包地址',
        trader_input_wallet_address: '请再次手动输入钱包地址',
        trader_wallet_address_must_be_consistent: '两次的钱包地址必须一致',
        trader_confirm: '确定',
        trader_cancel: '取消',
        input_trader_password: '请输入交易密钥',
        input_your_trader_password: '请输入您的交易密钥',
        confirm: '确定',
        transfer_confirm: '一键免密转账',
        email_format_error: '邮箱格式不正确',
        email_length_error: '邮箱长度需大于6位',
        wallet_add: '新增',
        wallet_edit: '编辑',
        wallet_edit2: '编辑',
        wallet_delete: '删除',
        wallet_type: '钱包类型',
        select_wallet_type: '请选择钱包类型',
        wallet_address: '钱包地址',
        wallet_address2: '钱包地址',
        input_wallet_address: '请输入钱包地址',
        select_wallet_address: '请选择钱包地址',
        save: '保存',
        input_right_wallet_address: '请输入正确的钱包地址',
        select_wallet: '请选择钱包',
        select_transfer_wallet: '请选择对方账户的钱包地址',
        wallet2: '钱包地址',
        wallet3: '钱包',
        amount: '提现金额',
        input_withdrawal_amount: '请输入提现金额',
        input_right_withdrawal_amount: '请输入正确提现金额',
        withdrawal_record: '提现记录',
        withdrawal_amount: '金额',
        withdrawal_status: '状态',
        withdrawal_date: '日期',
        withdrawal_left: '距下次提现：',
        no_next_withdrawal_time: '还未到此次可提现的时间',
        recharge_account: '充值账号',
        recharge_filling: '我要充值',
        recharge_image_tip: '请上传带有交易编号的转账截图',
        upload_voucher: '上传凭证',
        select_upload_voucher: '请上传充值凭证',
        recharge_amount: '充值金额',
        input_recharge_amount: '请输入充值金额',
        input_right_recharge_amount: '请输入正确充值金额',
        recharge_record: '充值记录',
        recharge_amount2: '金额',
        recharge_status: '状态',
        recharge_date: '日期',
        recharge_example: '充值示例',
        daily_money: '每日转入上限：',
        month_money: '每月转入上限：',
        limit_days: '有效天数：',
        upgrade_now: '立即升级',
        time_remaining: '有效期：',
        time_remaining2: '会员有效期剩余：',
        time_remaining3: '会员有效期：',
        daily_money_left: '今日转入剩余额度：',
        daily_money_left2: '今日转入剩余额度',
        month_money_left: '本月转入剩余额度：',
        month_money_left2: '本月转入剩余额度',
        select_vip_level: '请选择会员等级',
        select_higher_vip_level: '请购买比当前更高的等级',
        transfer_to_user: '对方账户',
        input_transfer_to_user: '请输入对方账户',
        transfer_to_user_tip: '温馨提示：对方账户必须满一级会员才能转入',
        transfer_amount: '转账金额',
        transfer_checking: '钱包地址校验中...',
        transfer_fee: '手续费：',
        transfer_received_amount: '立即到账：',
        transfer_time_remaining: '剩余时间：',
        transfer_auto_clear: '支付成功系统自动清算。',
        transfer_service_charge_tip1: '手续费支付成功，资金',
        transfer_service_charge_tip2: '立即到达你的钱包地址',
        transfer_service_charge_tip3: '。',
        transfer_service_charge_tip4: '该手续费将通过美国区块链数字货币交易所收取（并非本平台收取） 支付完USDT将立即到账。',
        transfer_service_charge_tip5: '每个会员提现周期 15 天只能提现成功一次。',
        input_transfer_amount: '请输入转账金额',
        transfer_amount_tip: '转账说明：对方账户必须满一级会员才能转入',
        copy_success: '复制成功',
        days: '天 ',
        day: '天 ',
        copy: '复制',
        vip_remark: '除VIP1，其他VIP会员等级所支付的USDT都是押金，当你的会员到期或者不再需要使用会员功能的时候可以向客服发邮件申请退回押金。开通会员不收取任何USDT。 ',

    },
    product: {
        agreement: '我已阅读并同意USDT《理财产品协议》',
        agreement_tip: '请勾选同意协议',
        financing_order: '理财订单',
        product_name: '产品名称',
        product_num: '产品数量',
        start_date: '开始日期',
        end_date: '结束日期',
        create_date: '订单时间',
        order_amount: '订单金额',
        income_amount: '收益金额',


    },
    common: {
        zh: '中文',
        en: 'English',
    }
}
