<template>
    <div id="app" :class="isApp?'ydd':''">
        <router-view/>
    </div>
</template>
<script>
    import Cookies from 'js-cookie'

    export default {
        name: 'app',
        computed: {
            isApp() {
                return document.documentElement.clientWidth < 800 ? true : false
            }
        },
        mounted() {
            let lang = Cookies.get('lang') ? Cookies.get('lang') : 'en'
            let lang_var = Cookies.get('lang_var') ? Cookies.get('lang_var') : 'en'
            this.$i18n.locale = lang
            Cookies.set('lang', lang)
            Cookies.set('lang_var', lang_var)
            // let isApp = document.documentElement.clientWidth < 800;
            // if(!isApp){
            //     window.onload = window.onresize = function () {
            //         document.body.style.zoom = 0.67;
            //     };
            // }
        }
    }
</script>
<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
