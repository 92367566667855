import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import( /* webpackChunkName: "about" */ '../views/container.vue'),
    redirect: '/home',
    children: [{
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: '/home/homePage',
        component: () => import( /* webpackChunkName: "about" */ '../views/homeindex.vue'),
        children: [{
            path: '/home/homePage',
            name: 'homePage',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/homeView.vue'),
          },
          {
            path: '/home/detail/:id',
            name: 'detail',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/detail/homeDetail.vue'),
          },
          {
            path: '/home/account',
            name: 'account',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account.vue')
          },
          {
            path: '/home/manageMoney',
            name: 'manageMoney',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/manageMoney.vue')
          },
          {
            path: '/home/success',
            name: 'success',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/detail/bysuccess.vue')
          },
          {
            path: '/home/levelup',
            name: 'levelup',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/uplevel.vue')
          },
          {
            path: '/home/investMoney',
            name: 'investMoney',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/investMoney.vue')
          },
          {
            path: '/home/cash',
            name: 'cash',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/cash.vue')
          },
          {
            path: '/home/transfer',
            name: 'transfer',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/transfer.vue')
          },
          {
            path: '/home/acSave',
            name: 'acSave',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/acSave.vue')
          },
          {
            path: '/home/myMoneyManage',
            name: 'myMoneyManage',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/myMoneyManage.vue')
          },
          {
            path: '/home/msglist',
            name: 'msglist',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/msglist.vue')
          },
          {
            path: '/home/moneyBag',
            name: 'moneyBag',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/moneyBag.vue')
          },
          {
            path: '/home/questions',
            name: 'questions',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/questions.vue')
          },
          {
            path: '/home/addBag',
            name: 'addBags',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/addbags.vue')
          },
          {
            path: '/home/vipTransfer',
            name: 'vipTransfer',
            component: () => import( /* webpackChunkName: "about" */ '../views/account/vipTransfer.vue')
          },
          {
            path: '/home/accountApp',
            name: 'accountApp',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '../views/account/accountApp.vue')
          },
        ]
      },

      {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
      },
    ]
  },


]

const router = new VueRouter({
  routes
})

export default router
